.bot-border {
  border-top: 1px solid #ffffff;
}

nav.sticky {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
}

.navi > .Link {
  color: blue;
}

.navi > .active {
  color: #3aafa9;
}

@media screen and (max-width: 750px) {
  .res-size {
    font-size: 12px;
  }
}
