.underline-dark-bar {
  background: #17252a;
  height: 4px;
  width: 70px;
  margin: auto;
}

.font-bebas {
  font-family: 'Bebas Neue', cursive;
  letter-spacing: 1.5px;
}

/* Projects
------------------------------------*/

.projects {
  background: #3aafa9;
}

.divider > section {
  overflow: hidden;
  border-bottom: 1px #343434;
  padding: 25px 0;
}
.project-item img {
  height: 200px; /* Adjust this value as needed */
  object-fit: cover;
  width: 300px;
}

.project-item p {
  text-align: justify;
}
.project-item h3 {
  text-decoration: underline;
}



@media screen and (min-width: 768px) {
  .project-item {
    display: grid !important;
    grid-template-columns: 1fr 2fr !important;
    column-gap: 20px !important;
  }
  .project-item div {
    height: 200px; /* Adjust this value as needed */
  }

  .align-items{
    text-align: left;
  }
}

@media screen and (max-width: 768px) {
  .res-marg {
    margin-top: 30px !important;
  }

 
}
