/* Contact Info
------------------------------------*/

footer {
  background: #eb9a05;
  color: #f7fff7;
}

.footer-items a {
  display: inline-block;
}

.footer-items {
  list-style-type: none;
  font-size: 3rem;
}

.footer-items i:hover {
  color: #17252a;
}

.footer-items i {
  color: rgb(232, 235, 240);
}

/* Responsive
------------------------------------*/

@media screen and (min-width: 750px) {
  footer {
    text-align: center;
  }
  .footer-items {
    display: flex;
    justify-content: center;

  }
  .footer-items a {
    padding: 15px;
  }
}
