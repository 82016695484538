.home-background {
  /* Removed the static background image */
  position: relative;
  background-color: #17252a; /* Set a dark background color as a fallback */
  height: 94vh;
}

.image {
  width: 120px;
}

.font-bebas {
  font-family: 'Bebas Neue', cursive;
  letter-spacing: 1.5px;
}

.font-courier {
  font-family: 'Courier Prime', monospace;
}

.top-gap {
  margin-top: 50px;
  padding-top: 50px;
}

.hex-comp {
  width: 400px;
  height: 200px;
  background: #2b7a78;
  transform: rotate(120deg);
  margin: auto;
  overflow: hidden;
  visibility: hidden;
}

.inside-comp {
  width: 100%;
  height: 100%;
  background: #2b7a78;
  transform: rotate(-60deg);
  overflow: hidden;
}

.img-comp {
  width: 100%;
  height: 100%;
  background-image: url('../../assets/profile.jpeg');
  background-position: center;
  background-size: 50%;
  transform: rotate(-60deg);
  visibility: visible;
}

@media screen and (max-width: 750px) {
  .top-gap {
    margin-top: 10px;
    padding-top: 10px;
  }
  .home-background {
    height: 100vh;
  }
}
