.skill-list {
  width: 20em;
}

.pro-h {
  height: 25px;
  width: 110%;
}

.extra-margin {
  margin-bottom: 100px;
}

.font-bebas {
  font-family: 'Bebas Neue', cursive;
  letter-spacing: 1.5px;
}

.bg-col {
  background-color: #feffff;
}

.underline-bar {
  background: #3aafa9;
  height: 4px;
  width: 70px;
  margin: auto;
}

.rotate-icon {
  transform: rotate(-30deg);
}

.skill-components {
  position: relative;
  margin: auto;
  padding: auto;
  width: 140px;
  height: 80px;
  background: #2b7a78;
  display: block;
  text-align: center;
  line-height: 80px;
  font-size: 72px;
  transition: 0.5s;
  transform: rotate(30deg);
}

.skill-components:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #2b7a78;
  z-index: -1;
  transform: rotate(60deg);
}

.skill-components:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #2b7a78;
  z-index: -1;
  transform: rotate(-60deg);
}
