.underline-bar {
  background: #3aafa9;
  height: 4px;
  width: 70px;
  margin: auto;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #17252a inset !important;
}

/*Change text in autofill textbox*/

input:-webkit-autofill {
  -webkit-text-fill-color: #f7ffff !important;
}
@media (max-width: 768px) {
  .success-container {
    height: 90px;
  }
}

@media (min-width: 768px) {
  .form-width {
    width: 50%;
  }
}
